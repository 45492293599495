import React, { useState, useEffect } from "react";
import BaseLayout from '../../components/common/Layout/BaseLayout';
import { AppProvider } from '../../context/AppContext';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MealPlanMappings from "./MealPlanMappings";
import RoomTypeMappings from "./RoomTypeMappings";
import HotelMappingsDashboard from './HotelMappingsDashboard';

import Mappings from "./Mappings";
// import FullFeaturedCrudGrid from "./FullFeaturedCrudGrid";
import ApprovalStore from "../../context/RoomTypeMealTypeApprovalContext";
import Badge from "@mui/material/Badge";
import { styled } from '@mui/material/styles';


const MappingTabs = () => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -12,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  const {
    roomTypeApprovalCount,
    mealPlanApprovalCount,
    incrementRoomTypeApprovalCount,
    decrementRoomTypeApprovalCount,
    incrementMealPlanApprovalCount,
    decrementMealPlanApprovalCount,
    fetchApprovalCount
  } = ApprovalStore();


  useEffect(() => {
    fetchApprovalCount()
  }, [])

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <AppProvider>
      <BaseLayout>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            // p: { xs: 1, sm: 2, md: 3 },
            py: 1
          }}
        >
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="Mappings Tab"
                variant="fullWidth"
              >
                <Tab label="Hotel Mappings" value="1" />
                <Tab
                  label={
                    <StyledBadge
                      badgeContent={roomTypeApprovalCount}
                      color="primary"
                    >
                      Room Type Mappings
                    </StyledBadge>
                  }
                  value="2"
                />
                <Tab
                  label={
                    <StyledBadge
                      badgeContent={mealPlanApprovalCount}
                      color="primary"
                    >
                      Meal Plan Mappings
                    </StyledBadge>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel className='p-0' value="1">
              <HotelMappingsDashboard />
            </TabPanel>
            <TabPanel className='p-0' value="2">
              <RoomTypeMappings />
            </TabPanel>
            <TabPanel value="3">
              <MealPlanMappings />
            </TabPanel>
          </TabContext>
        </Box>
      </BaseLayout>
    </AppProvider>
  );
};

export default MappingTabs;
