import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PriceHistory from "./PriceHistory";
const PriceHistoryDialog = ({ open, setOpen, tenantId, propertyId, roomType, mealPlan, ota, selfDataSource, date, masterData, currencySymbol }) => {

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open}
                onClose={() => {
                    handleClose()
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Tariff History

                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            handleClose()
                        }}
                        aria-label="close"
                        style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {date &&
                        <PriceHistory
                            tenantId={tenantId}
                            propertyId={propertyId}
                            roomType={roomType}
                            mealPlan={mealPlan}
                            ota={ota}
                            selfDataSource={selfDataSource}
                            date={date}
                            masterData={masterData}
                            currencySymbol={currencySymbol}
                        />
                    }

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PriceHistoryDialog;