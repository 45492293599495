import React, { useState, useEffect } from 'react'
// import { BrowserRouter as useNavigate, useLocation } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { setCookie } from '../services/auth.js';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../assets/styles/base.css';
import '../assets/styles/login.css';
import 'react-toastify/dist/ReactToastify.css';
import loginBg from '../assets/img/login-bg.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { toast, ToastContainer } from 'react-toastify';
import DOMPurify from 'dompurify';



const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState(null);
    const [initialPassword, setInitialPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showInitialPassword, setShowInitialPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userName, setUserName] = useState(null);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isNew, setIsNew] = useState(false);

    const validateToken = async (tokenToValidate) => {
        try {
            const response = await axios.post('/validate-reset-token', { token: tokenToValidate });
            setIsTokenValid(response.data.isValid);
            setUserName(response.data.username);
            setIsNew(response.data.is_new);
        } catch (error) {
            setIsTokenValid(false);
        } finally {
            setIsLoading(false);
        }
    };

    const resendToken = async () => {
        try {
            const response = await axios.post('/send_password_reset_token', { "username": userName, "is_resend": isNew ? true : false });
            toast.success(response?.data?.msg ? response.data.msg : "A reset link has been sent to you!", {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
            });
        } catch (error) {
            console.error("Error in sending token", error)
            toast.error(error?.response?.data?.msg ? error.response.data.msg : "Something went wrong!", {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
            });
        }
    }

    const resetPassword = async () => {
        try {
            if (!initialPassword || !confirmPassword) {
                toast.error("Please Enter a password!", {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
                return;
            }
            const sanitizedInitialPassword = DOMPurify.sanitize(initialPassword);
            const sanitizedConfirmPassword = DOMPurify.sanitize(confirmPassword);

            if (sanitizedInitialPassword !== initialPassword || sanitizedConfirmPassword !== confirmPassword) {
                toast.error("Character not allowed", {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
                return
            }
            if (sanitizedInitialPassword.length < 8) {
                toast.error("Password must be at least 8 characters long", {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
                return
            }
            if (sanitizedInitialPassword.length > 32) {
                toast.error("Password cannot have more than 32 characters", {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
                return
            }
            if (sanitizedInitialPassword !== sanitizedConfirmPassword) {
                toast.error("Password Mismatch!", {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                });
                return
            }

            let errors_arr = [];
            !(/[a-z]/.test(sanitizedInitialPassword)) && errors_arr.push("Password must contain at least one lowercase letter");
            !(/[A-Z]/.test(sanitizedInitialPassword)) && errors_arr.push("Password must contain at least one uppercase letter");
            !(/[0-9]/.test(sanitizedInitialPassword)) && errors_arr.push("Password must contain at least one number");
            !(/[!@#$%^&*]/.test(sanitizedInitialPassword)) && errors_arr.push("Password must contain at least one special character");
            !(sanitizedInitialPassword.length >= 8) && errors_arr.push("Password must be at least 8 characters long");
            (sanitizedInitialPassword.length > 32) && errors_arr.push("Password cannot have more than 32 characters");

            if (errors_arr.length) {
                toast.error(
                    <div>
                        <p>Password validation failed:</p>
                        <ul>
                            {errors_arr.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>,
                    {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        theme: "colored",
                    }
                );
                return;
            }

            const response = await axios.post(`/reset-password-via-tokens`, { "username": userName, "password": sanitizedInitialPassword, "token": token }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            toast.success(response?.data?.msg ? response.data.msg : "Password reset successfully!", {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
            });
            // navigate("/login")


        } catch (error) {
            console.error("Error in resetting password", error)
            toast.error(error?.response?.data?.msg ? error.response.data.msg : "Something went wrong!", {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
            });
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowInitialPassword = () => setShowInitialPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);



    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlToken = params.get('token');
        if (urlToken) {
            setToken(urlToken);
            validateToken(urlToken);
        } else {
            setIsLoading(false);
            navigate("*");
        }
    }, []);

    if (isLoading) {
        return (
            <div
                style={{
                    backgroundImage: `url(${loginBg})`,
                    width: "100.5vw",
                    height: "100vh",
                }}
                className="login-bg row"
            >

            </div>
        );
    }

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${loginBg})`,
                    width: "100.5vw",
                    height: "100vh",
                }}
                className="login-bg row"
            >
                {isTokenValid ?
                    <>
                        <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-center">

                        </div>
                        <div className="login-form-section col-sm-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                            <div className="bg-white corner p-5 w-80 d-flex justify-content-center align-items-center flex-column">
                                <h1 className="greeting">{isNew ? "SET" : "RESET"} PASSWORD!</h1>
                                <div className="orange-line"></div>
                                <div className="w-90 mt-3 d-flex flex-column justify-content-center align-items-start">
                                    <InputLabel htmlFor="create-password" className="bold-2 text-black">Create Password <span style={{ color: "red" }}>*</span></InputLabel>
                                    <OutlinedInput
                                        className="w-100"
                                        id="create-password"
                                        type={showInitialPassword ? 'text' : 'password'}
                                        onChange={(evt) => setInitialPassword(evt.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowInitialPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showInitialPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        size="small"
                                    />
                                </div>
                                <div className="w-90 mt-3 d-flex flex-column justify-content-center align-items-start">
                                    <InputLabel htmlFor="confirm-password" className="bold-2 text-black">Confirm Password <span style={{ color: "red" }}>*</span></InputLabel>
                                    <OutlinedInput
                                        className="w-100"
                                        id="confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        onChange={(evt) => setConfirmPassword(evt.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        size="small"

                                    />
                                </div>
                                <div className='w-90 mt-2 d-flex justify-content-end'>
                                    <Tooltip title={
                                        <div>
                                            <p className='m-0'>Your password must be at least 8 characters long.</p>
                                            <p className='m-0'>Your password cannot exceed 32 characters.</p>
                                            <p className='m-0'>Your password must contain at least one uppercase letter.</p>
                                            <p className='m-0'>Your password must contain at least one lowercase letter.</p>
                                            <p className='m-0'>Your password must contain at least one number.</p>
                                            <p className='m-0'>Your password must contain at least one special character (e.g., !, @, #, $, %).</p>
                                        </div>
                                    }>
                                        <IconButton sx={{ padding: 0 }}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <p className='ml-1 mb-0'>Password Instructions</p>
                                </div>
                                <div className='w-100 d-flex justify-content-center'>
                                    <Button class="w-80 p-2 btn-orange mt-4" onClick={resetPassword}>Submit</Button>
                                </div>
                                <div className="w-100 d-flex justify-content-center">
                                    <Button
                                        startIcon={
                                            <ExitToAppIcon style={{ display: "inline-flex" }} /> // Override the display
                                        }
                                        className="w-80 p-2 btn-orange-outlined mt-4"
                                        sx={{ textTransform: "none" }}
                                        onClick={() => { navigate("/login") }}
                                    >
                                        Go to Login
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </>


                    :
                    userName ?
                        <>
                            <div className="login-form-section d-flex justify-content-start align-items-center flex-column mt-4">
                                <div className="bg-white corner p-3 w-40 d-flex justify-content-center align-items-center flex-column">
                                    <h1 className="greeting">Password Reset Link Expired</h1>
                                    <div className="orange-line"></div>
                                    <div className='w-80 mt-3'>
                                        <p className='text-justify'>
                                            We're sorry, but the password reset link you tried to use is no longer valid.
                                            Simply click the button below, and we'll send you another one
                                        </p>
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <Button class="w-80 p-2 btn-orange" onClick={resendToken}>Resend Password Reset Link</Button>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <>
                            <div className="login-form-section d-flex justify-content-start align-items-center flex-column mt-4">
                                <div className="bg-white corner p-3 w-40 d-flex justify-content-center align-items-center flex-column">
                                    <h1 className="greeting">Invalid Reset Link</h1>
                                    <div className="orange-line"></div>
                                    <div className='w-80 mt-3'>
                                        <p className='text-justify'>
                                            It seems the reset link you used has been altered or is no longer valid. Please go back to your email and click on the original password reset link to proceed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                }

            </div>
            <ToastContainer />
        </>
    );
};

export default ResetPassword;
