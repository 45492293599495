import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import BaseLayout from '../components/common/Layout/BaseLayout'
import isAuthenticated from '../services/auth.js';
import { AppProvider, useAppContext } from '../context/AppContext';
import { UserContext } from '../App';
import Carousel from "react-multi-carousel";
import Grow from '@mui/material/Grow';
import { fetchToken, deleteToken } from '../Auth'
import axios from 'axios';

import "react-multi-carousel/lib/styles.css";

import hotelMainBg from '../assets/img/hotel-bg.jpg';
import userBg from '../assets/img/dashboard/userBg.png';
import techBg from '../assets/img/dashboard/gearsBg.png';
import usageBg from '../assets/img/dashboard/sandclockBg.png';
import hotelBg from '../assets/img/dashboard/buildingBg.png';
import mappingBg from '../assets/img/dashboard/analyticsBg.png';
import compBg from '../assets/img/dashboard/rupeeBg.png';
import DashboardSessionTracker from "../components/common/SessionTracker"


const Dashboard = () => {

  const navigate = useNavigate();

  const [dashboards, setDashboards] = useState([]);
  const [name, setName] = useState('');

  const { currUser } = useAppContext();

  const svgMap = {
    '2': compBg,
    '5': techBg,
    '6': userBg,
    '11': hotelBg,
    '32': usageBg,
    '33': mappingBg,
    '34': hotelBg,
    '35': compBg
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const customStyles = {
    item: {
      padding: '0 10px', // Adjust the gutter width here
    },
  };

  const getAssignedDashboards = async () => {
    try {
      const response = await axios.get("/get_assigned_dashboards", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setDashboards(response.data)
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    getAssignedDashboards()
  }, []);

  const showArrow = (id) => {
    // console.log(document.getElementById(id))
    // document.getElementById(id).style.opacity='1'
  }

  const hideArrow = (id) => {
    // document.getElementById(id).style.opacity='0'
  }

  return (
      // <BaseLayout>
      <>
      <DashboardSessionTracker dashboardId={37}> </DashboardSessionTracker>
        <section className='mt-3 container'>
          <div className='row' style={{ borderBottom: '1.4px solid #aaa' }}>
            <div style={{ borderRadius: '6px' }} className='py-3 px-2'>
              <h3 className='bold'>Hi, {currUser['first_name']}!</h3>
            </div>
          </div>



          <div className='my-5 d-flex flex-column'>
            <h5 className='bold'>Quick Links</h5>

            <Carousel
              className='w-100'
              swipeable={true}
              draggable={true}
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              keyBoardControl={true}
              transitionDuration={500}
              renderButtonGroupOutside={true}
              customStyles={customStyles}
            >
              {
                dashboards.map((item, idx) =>
                  <div className='my-2 w-100 p-2'>
                    <Grow
                      in={true}
                      {...(true ? { timeout: idx*200 } : {})}
                    >
                      <div onClick={() => navigate(item[2])} onMouseLeave={() => hideArrow(`dashboard-${item[0]}`)} onMouseEnter={() => showArrow(`dashboard-${item[0]}`)} className='w-100 d-flex justify-content-end flex-column dashboard-card bg-teal p-3 dashboard-card'
                        style={{ height: '150px', backgroundImage: `url(${svgMap[String(item[0])]})`, backgroundPositionX: '-50px', backgroundSize: '180px 180px', backgroundRepeat: 'no-repeat' }}>
                        <div className='w-100 row'>
                          <div className='col-11'>
                            <h5 className='bold mb-2'>{item[1]}</h5>
                          </div>
                          <div className='col-1 d-flex justify-content-end'>
                            <div id={`dashboard-${item[0]}`}><i className="fa-solid fa-arrow-right"></i></div>
                          </div>
                        </div>
                      </div>
                    </Grow>
                  </div>
                )
              }
            </Carousel>
          </div>
        </section>
        </>
      // </BaseLayout>
  );
};

export default Dashboard;