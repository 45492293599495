import React, { useEffect, useState } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import rightArrow from "../../../assets/img/rightArrow.png";
import leftArrow from "../../../assets/img/leftArrow.png";
import Typography from "@mui/material/Typography";
import axios from 'axios';
import { fetchToken } from '../../../Auth'
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from '@mui/material/Tooltip';



const RoomDetails = ({ tenantId, propertyId, ota, pms, date, setClubedRoomDetails, modalData, selfDataSource, isIntegrated }) => {
    const navigate = useNavigate();
    const [roomData, setRoomData] = useState([])

    let glideInstance = null;

    const soldOutImg = require(`../../../assets/img/sold_out_6.png`);
    const equivalentGIF = require(`../../../assets/img/magic-wand.gif`);
    const equivalentGIF2 = require(`../../../assets/img/magic-wand-2.gif`);
    const equivalentGIF3 = require(`../../../assets/img/magic-wand-3.gif`);

    const [currentSlide, setCurrentSlide] = useState(0);

    const getPaginationBullets = () => {
        if (!roomData.length) return [];
        const numberOfBullets = Math.ceil(roomData.length / 4);
        return Array.from({ length: numberOfBullets }, (_, index) => (
            <button
                key={index}
                className={`glide__bullet ${currentSlide === index ? 'active' : ''}`}
                data-glide-dir={`=${index}`}
            ></button>
        ));
    };

    useEffect(() => {
        let glideInstance = null;
        let isMounted = true;

        if (roomData.length > 0) {
            setTimeout(() => {
                if (!isMounted) return;

                const glideElement = document.querySelector('.glide');
                if (!glideElement) return;

                glideInstance = new Glide('.glide', {
                    type: 'carousel',
                    startAt: 0,
                    perView: 1,
                    focusAt: 'center',
                    gap: 10,
                    peek: 50,
                    breakpoints: {
                        1200: { perView: 3 },
                        800: { perView: 2 },
                        500: { perView: 1 }
                    }
                });

                glideInstance.on('run.after', () => {
                    if (isMounted) setCurrentSlide(glideInstance.index);
                });

                glideInstance.mount();
            }, 0);
        }

        return () => {
            isMounted = false;
            if (glideInstance) {
                try {
                    glideInstance.destroy();
                } catch (error) {
                    console.warn('Error destroying Glide:', error);
                }
            }
        };
    }, [roomData]);

    const calculateAvailability = (data) => {
        let totalInventorySum = 0;
        let avlInventorySum = 0;

        data.forEach(item => {
            totalInventorySum += item.total_inventory;
            avlInventorySum += item.avl_inventory;
        });

        const availability = avlInventorySum;
        const percentage = ((totalInventorySum - avlInventorySum) / totalInventorySum) * 100;

        return {
            avl_inventory: availability,
            total_inventory: totalInventorySum,
            curr_occ: parseInt(percentage)

        };
    };

    const populateRoomDetails = async () => {
        try {
            const response = await axios.get(`/fetch_pms_room_types/${ota}/${pms}/${tenantId}/${propertyId}/${date}`, // Removed the extra quotation mark
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                });
            if (response.status === 200) {
                const clubedDetails = calculateAvailability(response.data)
                setClubedRoomDetails(clubedDetails)
                setRoomData(response.data)
            }

        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }


    const capitalizeFirstWord = (str) => {
        try {
            const words = str.replace(/[-_]/g, ' ').split(' ');

            return words.map(word => {
                if (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
                return word;
            }).join(' ');
        } catch (error) {
            console.error(error)
            return ""
        }
    };

    // useEffect(() => {
    //     const baseRoom = {
    //         room_type: isIntegrated ? modalData.pms_room_type : modalData.room_type,
    //         room_type_id: isIntegrated ? modalData.pms_room_type_id : modalData.room_type_id,
    //         meal_plan: isIntegrated ? modalData.pms_meal_plan : modalData.meal_plan,
    //         meal_plan_id: isIntegrated ? modalData.pms_meal_plan_id : modalData.meal_plan_id,
    //         price_info: modalData.price_info?.[selfDataSource] || [],
    //         is_sold_out: modalData.is_sold_out?.[selfDataSource],
    //         available_room_count: modalData.available_room_count,
    //         total_room_count: modalData.total_room_count,
    //         isBaseRoom: true
    //     };

    //     const equivalentRoom = modalData?.['equivalent_room']?.[selfDataSource] &&
    //         Object.keys(modalData['equivalent_room'][selfDataSource]).length ?
    //         modalData['equivalent_room'][selfDataSource] : {};

    //     if (modalData?.['self']?.[selfDataSource]) {
    //         const otherRooms = Object.entries(modalData['self'][selfDataSource])
    //             .map(([key, room]) => {
    //                 const isEquivalentRoom = room.room_type_id === equivalentRoom.room_type_id;
    //                 if (`${room.room_type_id}|${room.meal_plan_id}` !== `${baseRoom.room_type_id}|${baseRoom.meal_plan_id}`) {
    //                     return {
    //                         ...room,
    //                         key,
    //                         isBaseRoom: false,
    //                         is_equivalent_room: baseRoom.is_sold_out && isEquivalentRoom ? isEquivalentRoom : false
    //                     };
    //                 }
    //                 return null;
    //             })
    //             .filter(Boolean);

    //         const allRooms = [baseRoom, ...otherRooms];

    //         const sortedRooms = allRooms.sort((a, b) => {
    //             // If base room is not sold out, it should be first
    //             if (!a.is_sold_out && a.isBaseRoom) return -1;
    //             if (!b.is_sold_out && b.isBaseRoom) return 1;

    //             // If base room is sold out, equivalent room should be first
    //             if (baseRoom.is_sold_out) {
    //                 if (a.is_equivalent_room) return -1;
    //                 if (b.is_equivalent_room) return 1;
    //             }

    //             // For remaining rooms, show available rooms before sold out ones
    //             if (a.is_sold_out !== b.is_sold_out) {
    //                 return a.is_sold_out ? 1 : -1;
    //             }

    //             return 0;
    //         });

    //         setRoomData(sortedRooms);
    //     } else {
    //         setRoomData([baseRoom]);
    //     }
    // }, [modalData, selfDataSource]);

    // useEffect(() => {
    //     const prioritizedRoomType = 100;
    //     const baseRoom = {
    //         room_type: isIntegrated ? modalData.pms_room_type : modalData.room_type,
    //         room_type_id: isIntegrated ? modalData.pms_room_type_id : modalData.room_type_id,
    //         meal_plan: isIntegrated ? modalData.pms_meal_plan : modalData.meal_plan,
    //         meal_plan_id: isIntegrated ? modalData.pms_meal_plan_id : modalData.meal_plan_id,
    //         price_info: modalData.price_info?.[selfDataSource] || [],
    //         is_sold_out: modalData.is_sold_out?.[selfDataSource],
    //         available_room_count: modalData.available_room_count,
    //         total_room_count: modalData.total_room_count,
    //         isBaseRoom: true
    //     };

    //     const equivalentRoom = modalData?.['equivalent_room']?.[selfDataSource] &&
    //         Object.keys(modalData['equivalent_room'][selfDataSource]).length ?
    //         modalData['equivalent_room'][selfDataSource] : {};

    //     if (modalData?.['self']?.[selfDataSource]) {
    //         // Group rooms by room_type_id (excluding base room type)
    //         const roomsByType = new Map();

    //         // Process other rooms (excluding base room type)
    //         Object.entries(modalData['self'][selfDataSource])
    //             .forEach(([key, room]) => {
    //                 // Skip if it's the same room type as base room
    //                 if (room.room_type_id === baseRoom.room_type_id) {
    //                     return;
    //                 }

    //                 const isEquivalentRoom = room.room_type_id === equivalentRoom.room_type_id;
    //                 const roomData = {
    //                     ...room,
    //                     key,
    //                     isBaseRoom: false,
    //                     is_equivalent_room: baseRoom.is_sold_out && isEquivalentRoom ? isEquivalentRoom : false
    //                 };

    //                 // Check if we already have a room of this type
    //                 const existingRoom = roomsByType.get(room.room_type_id);

    //                 if (!existingRoom) {
    //                     roomsByType.set(room.room_type_id, roomData);
    //                 } else {
    //                     // Priority logic for meal plans
    //                     const shouldReplace =
    //                         // If existing room doesn't have meal_plan_id 100 and current room has it
    //                         (parseInt(existingRoom.meal_plan_id) !== prioritizedRoomType && parseInt(roomData.meal_plan_id) === prioritizedRoomType) ||
    //                         // If neither has meal_plan_id 100, choose the one with lower meal_plan_id
    //                         (parseInt(existingRoom.meal_plan_id) !== prioritizedRoomType && parseInt(roomData.meal_plan_id) !== prioritizedRoomType &&
    //                             roomData.meal_plan_id < existingRoom.meal_plan_id);

    //                     if (shouldReplace) {
    //                         roomsByType.set(room.room_type_id, roomData);
    //                     }
    //                 }
    //             });

    //         // Combine base room with other rooms
    //         const allRooms = [baseRoom, ...Array.from(roomsByType.values())];

    //         const sortedRooms = allRooms.sort((a, b) => {
    //             // If base room is not sold out, it should be first
    //             if (!a.is_sold_out && a.isBaseRoom) return -1;
    //             if (!b.is_sold_out && b.isBaseRoom) return 1;

    //             // If base room is sold out, equivalent room should be first
    //             if (baseRoom.is_sold_out) {
    //                 if (a.is_equivalent_room) return -1;
    //                 if (b.is_equivalent_room) return 1;
    //             }

    //             // For remaining rooms, show available rooms before sold out ones
    //             if (a.is_sold_out !== b.is_sold_out) {
    //                 return a.is_sold_out ? 1 : -1;
    //             }

    //             return 0;
    //         });

    //         setRoomData(sortedRooms);
    //     } else {
    //         setRoomData([baseRoom]);
    //     }
    // }, [modalData, selfDataSource]);

    useEffect(() => {
        const baseRoom = {
            room_type: isIntegrated ? modalData.pms_room_type : modalData.room_type,
            room_type_id: isIntegrated ? modalData.pms_room_type_id : modalData.room_type_id,
            meal_plan: isIntegrated ? modalData.pms_meal_plan : modalData.meal_plan,
            meal_plan_id: isIntegrated ? modalData.pms_meal_plan_id : modalData.meal_plan_id,
            price_info: modalData.price_info?.[selfDataSource] || [],
            is_sold_out: modalData.is_sold_out?.[selfDataSource],
            available_room_count: modalData.available_room_count,
            total_room_count: modalData.total_room_count,
            isBaseRoom: true
        };
        const equivalentRoom = baseRoom.is_sold_out && modalData?.['equivalent_room']?.[selfDataSource] &&
            Object.keys(modalData['equivalent_room'][selfDataSource]).length > 1 ?
            {
                ...modalData['equivalent_room'][selfDataSource],
                is_equivalent_room: baseRoom.is_sold_out ? true : false, // the equivalent room type must not be shown if the anchor is not sold out
                isBaseRoom: false
            } : null;

        // the Object.keys(modalData['equivalent_room'][selfDataSource]).length > 1 condition is put so that when the equivalent room has only one key is_anchor then it should not be considered

        if (modalData?.['self']?.[selfDataSource]) {
            // Group rooms by room_type_id (excluding base and equivalent room types)

            let epRooms = [];
            const nonEPRooms = new Map();

            // Process other rooms
            Object.entries(modalData['self'][selfDataSource])
                .forEach(([key, room]) => {
                    // Skip if it's the same room type as base room or equivalent room

                    if ((parseInt(room.room_type_id) === parseInt(baseRoom.room_type_id)) ||
                        (equivalentRoom && parseInt(room.room_type_id) === parseInt(equivalentRoom.room_type_id))) {
                        return;
                    }

                    const roomData = {
                        ...room,
                        key,
                        isBaseRoom: false,
                        is_equivalent_room: false
                    };
                    if (parseInt(roomData.meal_plan_id) === 100) {
                        epRooms.push(roomData)
                    } else {
                        if (!roomData.is_sold_out) {
                            const existingRoom = nonEPRooms.get(room.room_type_id);
                            if (!existingRoom) {
                                nonEPRooms.set(room.room_type_id, roomData)
                            } else {
                                const shouldReplace = roomData.price_info[1] < existingRoom.price_info[1]
                                if (shouldReplace) {
                                    nonEPRooms.set(room.room_type_id, roomData)
                                }
                            }
                        }
                    }


                    // Check if we already have a room of this type
                    // const existingRoom = roomsByType.get(room.room_type_id);

                    // if (!existingRoom) {
                    //     roomsByType.set(room.room_type_id, roomData);
                    // } else {
                    //     // Priority logic for meal plans
                    //     const shouldReplace =
                    //         // If existing room doesn't have meal_plan_id 100 and current room has it
                    //         (existingRoom.meal_plan_id !== 100 && roomData.meal_plan_id === 100) ||
                    //         // If neither has meal_plan_id 100, choose the one with lower meal_plan_id
                    //         (existingRoom.meal_plan_id !== 100 && roomData.meal_plan_id !== 100 &&
                    //             roomData.price_info[1] < existingRoom.price_info[1]);

                    //     if (shouldReplace) {
                    //         roomsByType.set(room.room_type_id, roomData);
                    //     }
                    // }
                });

            // Combine base room, equivalent room (if exists), and other rooms
            const allRooms = [
                baseRoom,
                ...epRooms,
                ...(equivalentRoom ? [equivalentRoom] : []),
                ...Array.from(nonEPRooms.values()),
            ];

            const sortedRooms = allRooms.sort((a, b) => {
                // If base room is not sold out, it should be first
                if (!a.is_sold_out && a.isBaseRoom) return -1;
                if (!b.is_sold_out && b.isBaseRoom) return 1;

                // If base room is sold out, equivalent room should be first
                if (baseRoom.is_sold_out) {
                    if (a.is_equivalent_room) return -1;
                    if (b.is_equivalent_room) return 1;
                }

                // For remaining rooms, show available rooms before sold out ones
                if (a.is_sold_out !== b.is_sold_out) {
                    return a.is_sold_out ? 1 : -1;
                }

                return 0;
            });
            setRoomData(sortedRooms);
        } else {
            setRoomData([baseRoom]);
        }
    }, [modalData, selfDataSource]);



    return (
        <>
            {roomData && roomData.length ?
                <>
                    <h5 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Room Details</h5>
                    <div className="glide" id="roomCarousel" style={{ position: 'relative' }}>
                        <div className="glide__track" data-glide-el="track" >

                            <ul className="glide__slides">
                                {roomData.map((room, index) => {
                                    if (index % 4 === 0) {
                                        const groupItems = roomData.slice(index, index + 4);
                                        let colClass = 'col-2';

                                        if (groupItems.length === 1 || groupItems.length === 2) {
                                            colClass = 'col-4';
                                        } else if (groupItems.length === 3) {
                                            colClass = 'col-3';
                                        }

                                        return (
                                            <li key={index} className="glide__slide">
                                                <div className="d-flex justify-content-evenly">
                                                    {groupItems.map((innerRoom, innerIndex) => {
                                                        let altRoom;
                                                        const extensions = ['png', 'jpeg', 'jpg'];
                                                        let imageFound = false;
                                                        for (const ext of extensions) {
                                                            try {
                                                                altRoom = require(`../../../assets/img/master/${innerRoom.room_type}.${ext}`);
                                                                imageFound = true;
                                                                break;
                                                            } catch (e) {
                                                                continue;
                                                            }
                                                        }

                                                        if (!imageFound) {
                                                            altRoom = "https://images.pexels.com/photos/1457842/pexels-photo-1457842.jpeg?cs=srgb&dl=pexels-jvdm-1457842.jpg&fm=jpg";
                                                        }

                                                        return (
                                                            <div key={innerIndex} className={`card-insights-container ${colClass} m-2`}>
                                                                <div className={`mb-2 card-img-container ${innerRoom.is_sold_out && 'grayscale-div'}`} style={{ backgroundImage: `url(${altRoom})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                                                    <div className="extended-room-chip">
                                                                        {capitalizeFirstWord(innerRoom.room_type)} Room
                                                                    </div>

                                                                    {innerRoom.isBaseRoom &&
                                                                        <div className="base-room-chip"
                                                                            style={{ background: 'linear-gradient(90deg, #FFD580 30%, #FF7F00 100%)' }}
                                                                        >
                                                                            Base Room
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {innerRoom.is_sold_out && (
                                                                    <div
                                                                        className="non-grayscale-div"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 'calc(100% - 11em)',
                                                                            right: '3px',
                                                                        }}
                                                                    >
                                                                        <img src={soldOutImg} alt="" style={{ height: '3em', width: '3em' }} />
                                                                    </div>
                                                                )}
                                                                {innerRoom.is_equivalent_room && (
                                                                    <div
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 'calc(100% - 10em)',
                                                                            right: '3px',
                                                                            borderRadius: '50%',
                                                                            background: 'white',
                                                                            width: '2em',
                                                                            height: '2em',
                                                                        }}
                                                                        className='d-flex justify-content-center align-items-center'
                                                                    >
                                                                        {/* <img
                                                                            src={equivalentGIF3}
                                                                            alt=""
                                                                            style={{
                                                                                height: '2em',
                                                                                width: '2em',
                                                                                objectFit: 'contain',
                                                                                borderRadius: '50%'
                                                                            }}
                                                                        /> */}
                                                                        <AutoFixHighIcon
                                                                            style={{ color: "orange", fontSize: "1.5em" }}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="card-body-insights">
                                                                    <div className="card-item">
                                                                        <div>CT</div>
                                                                        <div>
                                                                            {!innerRoom.is_sold_out && innerRoom.price_info?.length > 0
                                                                                ? `₹ ${Math.round(innerRoom.price_info[1]).toLocaleString("en-IN")}`
                                                                                : '-'}
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-item">
                                                                        <div>MP</div>
                                                                        <div>{innerRoom.meal_plan || '-'}</div>
                                                                    </div>
                                                                    <div className="card-item">
                                                                        <div>A/T</div>
                                                                        <div>{innerRoom?.available_room_count ?? '-'}/{innerRoom?.total_room_count ?? '-'}</div>
                                                                    </div>
                                                                    <div className="card-item">
                                                                        <div>Curr Occ :</div>
                                                                        <div>{
                                                                            (() => {
                                                                                const available = innerRoom?.available_room_count;
                                                                                const total = innerRoom?.total_room_count;
                                                                                return (available !== undefined && total !== undefined && total !== 0)
                                                                                    ? `${Math.round(((total - available) / total) * 100)}%`
                                                                                    : '-'
                                                                            })()
                                                                        }</div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                            </ul>
                        </div>

                        {roomData.length > 4 && (
                            <>
                                <div className="glide__arrows" data-glide-el="controls">
                                    <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                        <KeyboardArrowLeftIcon className="text-black font-bold text-2xl" />
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                        <KeyboardArrowRightIcon className="text-black text-2xl" />
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>

                                <div className="glide__bullets" data-glide-el="controls[nav]">
                                    {getPaginationBullets()}
                                </div>
                            </>
                        )}


                    </div>

                    <div className="carousel-legend mb-2 d-flex justify-content-center">
                        {/* <div className="legend-item">
                        <span className="legend-color overselling"></span>
                        <span>Over Selling</span>
                        </div> */}
                        {/* <div className="legend-item">
                        <span className="legend-color underselling"></span>
                        <span>Under Selling</span>
                        </div> */}
                        <div className="legend-item">
                            <span>CT: Current Tariff</span>
                        </div>

                        <div className="legend-item">
                            <span>MP: Meal Plan</span>
                        </div>
                        <div className="legend-item">
                            <span>A/T: Available Rooms/Total Rooms</span>
                        </div>
                        <div className="legend-item">
                            <span>Curr Occ: Current Room Occupancy</span>
                        </div>

                        {/* <div className="legend-item">
                            <span>A/T: Rooms Available</span>
                        </div> */}
                        {/* <div className="legend-item">
                            <span>Curr Occ: Current Room Occupancy</span>
                        </div> */}

                        {/* <div className="legend-item">
                        <span>Past Occ: Past 4 weeks same DoW Occupancy</span>
                        </div> */}
                    </div>
                </>
                :
                <>
                    {/* <h5 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Room Details</h5>
                    <div className='row py-4 px-5'>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                        <div className='d-flex justify-content-center col-4 p-1'>
                            <Stack className='w-100' spacing={1}>
                                <Skeleton variant="rounded" width={330} height={80} />
                                <Skeleton variant="rounded" width={330} height={100} />
                            </Stack>
                        </div>
                    </div> */}
                </>
            }
        </>

    )
}

export default RoomDetails