import { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import { AppProvider } from '../../context/AppContext';
import { isValidRoute } from '../../Auth';

import Dashboard from './Dashboard';
import Mappings from './Mappings';
import HotelMappingsDashboard from './HotelMappingsDashboard';

const HotelMappings = ({ match }) => {
  const navigate = useNavigate()

  const checkRoute = async () => {
    const res = await isValidRoute(33)
    if (!res['valid']) {
      navigate(res['redirect'])
    }
  }

  useEffect(() => {
    checkRoute()
  }, [])

  return (
    <AppProvider>
      <BaseLayout>
        <Routes>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="mappings" element={<HotelMappingsDashboard />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default HotelMappings;