import React from 'react'
import BaseLayout from '../components/common/Layout/BaseLayout'
import { AppProvider, useAppContext } from '../context/AppContext';
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { fetchToken } from "../Auth";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import '../assets/styles/home.css';
import Highcharts from "highcharts";
import Speedometer from "./Speedometer";
import HighchartsReact from "highcharts-react-official";
import Switch from '@mui/material/Switch';
import { Skeleton } from "@mui/material";
import LinearProgress from "./LinearProgress"
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DashboardSessionTracker from "../components/common/SessionTracker"

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const Home = () => {
  const soldOutImg = require(`../assets/img/sold_out_6.png`);
  const navigate = useNavigate();
  const { currUser } = useAppContext();
  const [ota, setOTA] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedPropertyName, setSelectedPropertyName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [properties, setProperties] = useState([]);
  const [selectedRoomTypes, setSelectedRoomTypes] = useState("all_rooms")
  const [selectedRoomname, setSelectedRoomName] = useState("")
  const [checkInDate, setCheckInDate] = useState()
  const [isLoaded, setIsLoaded] = useState(false)

  const [roomTypes, setRoomTypes] = useState([])
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [table4Data, setTable4Data] = useState([]);

  const [occupancyPast, setOccupancyPast] = useState(0);
  const [occupancyLDOW, setOccupancyLDOW] = useState(0);
  const [occpuancyLysplit, setOccupancyLysplit] = useState(0);
  const [roomSoldData, setRoomSoldData] = useState([])
  const [revenueData, setRevenueData] = useState([])
  const [filteredRevenueData, setFilteredRevenueData] = useState([])
  const [filteredRoomSold, setFilteredRoomSold] = useState([])
  const [roomRevenueData, setRoomRevenueData] = useState({})
  const [categories, setCategories] = useState([])
  const [bookingData, setBookingData] = useState([])
  const [adrData, setAdrData] = useState([])
  const [date, setDate] = useState()
  const [compProperty, setCompProperty] = useState("")
  const [allCompPosition, setAllCompPosition] = useState([])


  function isValidNumber(value) {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && isFinite(numberValue);
  }

  useEffect(() => {
    const today = new Date();

    // Calculate yesterday's date
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formattedCheckInDate = yesterday.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'

    // Calculate last day of the week (7 days before the check-in date)
    const lastDow = new Date(today);
    lastDow.setDate(today.getDate() - 8);
    const formattedLastDow = lastDow.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'

    // Calculate the same date from 1 year ago (52 weeks ago)
    const lySpit = new Date(today);
    lySpit.setDate(today.getDate() - 365); // Subtract 365 days
    const formattedLySpit = lySpit.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'


    // Set the dates
    setDate([formattedCheckInDate, formattedLastDow, formattedLySpit]);


    setCheckInDate(formattedCheckInDate)
    getProperties();

  }, [])

  // useEffect(()=>{
  //   getInsights()
  // },[properties,roomTypes])

  const [isWeekdayView, setIsWeekDayView] = useState(false)
  function groupDataByWeekday(data) {
    const weekdayTotals = { Monday: 0, Tuesday: 0, Wednesday: 0, Thursday: 0, Friday: 0, Saturday: 0, Sunday: 0 };

    data.forEach(([timestamp, value]) => {
      const date = new Date(timestamp);
      const weekday = date.getDay();

      // Map weekday number to name
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayName = daysOfWeek[weekday];

      weekdayTotals[dayName] += value;
    });

    const chartData = Object.keys(weekdayTotals).map(day => ([
      day,
      weekdayTotals[day]
    ]));

    return chartData;
  }


  const handleDayOfWeekChange = (event) => {
    if (event.target.checked) {
      setIsWeekDayView(true)

      // Function to group data by weekday and sum the values


      // Get the data formatted for Highcharts
      const rev = groupDataByWeekday(revenueData);
      const room = groupDataByWeekday(roomSoldData)

      setFilteredRoomSold(room)
      setFilteredRevenueData(rev)
    }
    else {
      setIsWeekDayView(false)
      setFilteredRevenueData(revenueData)
      setFilteredRoomSold(roomSoldData)
    }

  }


  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  const DateComponent = (data) => {
    let updatedDate = new Date(data.value)
    return (
      <>
        <div className='single-date ml-2' style={{ fontSize: `${data.fontSize}em` }}>
          {formatDate(updatedDate)}
        </div>
      </>
    )
  }

  const DateRangeComponent = ({ value, week, fontSize }) => {
    if (week) {

      const startDate = new Date(value);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() - 14);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
      };

      return (
        <div className='single-date' style={{ fontSize: `${fontSize}em` }}>
          {formatDate(endDate)} - {formatDate(startDate)}
        </div>
      );
    }
    else {
      const startDate = new Date(value);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() - 30);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
      };

      return (
        <div className='single-date' style={{ fontSize: `${fontSize}em` }}>
          {formatDate(endDate)} to  {formatDate(startDate)}
        </div>
      );
    }

  };
  const options = {
    chart: {
      type: "line",
      zoomType: "x",

      // height:350,
    },
    title: {
      text: '', // Remove the title text
    },
    xAxis: {
      type: isWeekdayView ? "category" : "datetime",
      labels: {
        format: isWeekdayView ? "{value}" : "{value:%Y-%m-%d}"
      },
      categories: isWeekdayView ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] : undefined,
      tickInterval: isWeekdayView ? null : 24 * 3600 * 1000,
    },
    yAxis: [
      {
        title: {
          text: "Revenue",
        },
        labels: {
          formatter: function () {
            return `₹${this.value}`;
          },
        },

      },
      {
        title: {
          text: "Room Sold",
        },
        labels: {
          formatter: function () {
            return this.value;
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: false,
      xDateFormat: "%Y-%m-%d",
      formatter: function () {
        if (this.series.name === "Revenue") {
          return `Revenue: ₹${new Intl.NumberFormat('en-IN').format(this.y)}`;
        }
        if (this.series.name === "Room Sold") {
          return `Room Sold: ${this.y} rooms`;
        }
      },
    },
    series: [

      {
        name: "Revenue",
        data: filteredRevenueData,
        color: "#000000",
        tooltip: {
          valuePrefix: "₹",
        },
      },
      {
        name: "Room Sold",
        color: "orange",

        data: filteredRoomSold,
        tooltip: {
          valueSuffix: " rooms",
        },
        yAxis: 1,
      },
    ],
  };

  const options2 = {
    chart: {
      type: "column", // Column chart (vertical bars)
    },
    title: {
      text: '', // Remove the title text
    },
    xAxis: {
      categories: categories, // Categories derived from keys
      labels: {
        style: {
          fontSize: "10px",
        },
      },
      title: {
        text: "Date Range", // X-axis title
      },
    },
    yAxis: [
      {
        title: {
          text: "Bookings", // Left Y-axis title
        },
        labels: {
          formatter: function () {
            return this.value;
          },
        },
      },
      {
        title: {
          text: "ADR", // Right Y-axis title
        },
        labels: {
          formatter: function () {
            return `₹${this.value}`;
          },
        },
        opposite: true, // Place the second Y-axis on the right
      },
    ],
    tooltip: {
      shared: false,
      formatter: function () {
        if (this.series.name === "ADR") {
          return `ADR: ₹${new Intl.NumberFormat('en-IN').format(Math.round(this.y))}`;
        }
        if (this.series.name === "Bookings") {
          return `Bookings : ${this.y}`;
        }
      },

    },

    series: [
      {
        name: "Bookings",
        data: bookingData, // Data points for Booking Data
        color: "#000000", // Bar color for Booking Data
      },
      {
        name: "ADR",
        data: adrData, // Data points for ADR
        color: "#ff7f0e", // Bar color for ADR
        yAxis: 1, // Use the second Y-axis (right)
        tooltip: {
          valuePrefix: "₹",
        },
      },
    ],
  };



  const getProperties = async () => {
    try {
      const response = await axios.get(`/fetch_client_properties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setTenantId(parseInt(response.data.client_id));
        setProperties(response.data.client_properties);
        setSelectedProperty("all_props")
      }
    }
    catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };
  const handleRoomTypesChangedReport = (event) => {
    const selectedValue = event.target.value;
    setSelectedRoomTypes(selectedValue);

    if (selectedValue === "all") {
      setSelectedRoomName("All Room Types");
    } else {
      const tempRoom = roomTypes.find((room) => room.roomId === selectedValue);
      setSelectedRoomName(tempRoom ? tempRoom.roomName : "");
    }
  };

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value);
    const tempProperty = properties.find(
      (property) => property.propertyId === event.target.value
    );
    setSelectedPropertyName(tempProperty?.propertyName);
  };


  const handleCompetition = (event) => {
    setCompProperty(event.target.value);
    let target_id = event.target.value
    const result = {
      headers: allCompPosition.headers,
      rows: [],
      sold_out_comp: 0,
      total_comp: 0,
    };

    const targetData = allCompPosition[target_id];
    if (targetData) {

      result.rows = targetData.comp_data || [];
      result.sold_out_comp = targetData.sold_out || 0;
      result.total_comp = targetData.total_comp || 0;
    }
    setTable2Data(result)
    setCompPosition({
      "sold_out_comp": result.sold_out_comp,
      "total_comp": result.total_comp
    })
  };


  const getPreferredOTA = async () => {
    try {
      const response = await axios.get(`/get_preferred_ota/${selectedProperty}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      setOTA(response.data)
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  }






  const getSeriesData = (value) => {
    // return new Promise((resolve) => {
    const dates = Object.keys(value);
    const roomSoldData = [];
    const revenueData = [];


    dates.forEach((date) => {
      const [roomSold, revenue] = value[date].split("|").map(Number);
      const timestamp = new Date(date).getTime();

      roomSoldData.push([timestamp, roomSold]);
      revenueData.push([timestamp, revenue]);
    });

    setRoomSoldData(roomSoldData)
    setRevenueData(revenueData)
    setFilteredRevenueData(roomSoldData)
    setFilteredRoomSold(revenueData)

    // resolve({ roomSoldData, revenueData });
    // });
  };

  const populateData = async (data, booking) => {
    const aggregatedData = data.reduce(
      (acc, doc) => {
        const { total_revenue, total_rooms_sold, total_room_count, available_room_count } = doc.room_data || {};
        const date = doc.check_in;

        // Update aggregated totals
        acc.totalRevenue += total_revenue || 0;
        acc.totalRoomsSold += total_rooms_sold || 0;
        acc.totalRoomCount += total_room_count || 0;
        acc.totalAvailableRoom += available_room_count || 0;

        if (date) {
          const timestamp = new Date(date).getTime();

          // Build roomSoldData and revenueData arrays
          let total_rev = parseInt(total_revenue).toLocaleString('en-IN')
          acc.roomSoldData.push([timestamp, total_rooms_sold || 0]);
          acc.revenueData.push([timestamp, total_revenue || 0]);
        }

        // Push into daily metrics
        acc.dailyMetrics.push({
          date,
          revenue: total_revenue || 0,
          roomsSold: total_rooms_sold || 0,
          totalRoomCount: total_room_count || 0,
        });

        return acc;
      },
      {
        totalRevenue: 0,
        totalRoomsSold: 0,
        totalRoomCount: 0,
        totalAvailableRoom: 0,
        dailyMetrics: [],
        roomSoldData: [],
        revenueData: [],
      }
    );


    setRoomSoldData(aggregatedData.roomSoldData);
    setRevenueData(aggregatedData.revenueData);

    setFilteredRevenueData(aggregatedData.revenueData);
    setFilteredRoomSold(aggregatedData.roomSoldData);


    // Calculate derived metrics
    const { totalRevenue, totalRoomsSold, totalRoomCount, totalAvailableRoom, dailyMetrics } = aggregatedData;
    const RevPAR = totalRoomCount ? totalRevenue / totalRoomCount : 0;
    const Occupancy = totalRoomCount ? (totalRoomsSold / totalRoomCount) * 100 : 0;
    const ADR = totalRoomsSold ? totalRevenue / totalRoomsSold : 0;
    const past_30_days = [
      ["Revenue", Math.round(totalRevenue) > 0 ? formatPrice(Math.round(totalRevenue)) : "-"],
      ["RevPAR", Math.round(RevPAR) > 0 ? formatPrice(Math.round(RevPAR)) : "-"],
      ["ADR", Math.round(ADR) > 0 ? formatPrice(Math.round(ADR)) : "-"],
      ["Occupancy", Math.round(Occupancy) > 0 ? `${Math.round(Occupancy)}%` : "-"],
      ["Rooms Sold", totalRoomsSold > 0 ? totalRoomsSold.toString() : "-"],
      // ["Available Rooms", totalAvailableRoom > 0 ? totalAvailableRoom.toString() : "-"],
    ];
    setTable3Data(past_30_days);
  }

  const processChartData = async (value) => {
    // return new Promise((resolve) => {
    const dateRanges = value;
    const categories = [];
    const bookingData = [];
    const adrData = [];
    const today = new Date();

    Object.keys(dateRanges).forEach((key) => {
      const [booking, adr] = dateRanges[key].split("|").map(Number);
      bookingData.push(booking);
      adrData.push(adr);

      // Generate readable label and date range
      // const label = key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());

      const label = key
        .replace(/\|/g, "-")
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase());
      let range = "";

      const formatDate = (date) => {
        return date.toLocaleDateString("en-GB").replace(/\//g, "/"); // Convert to "dd-mm-yy"
      };
      let today = new Date()
      switch (key) {
        case "before_6_months":
          range = `${formatDate(new Date(new Date(new Date().setMonth(today.getMonth() - 6)).setDate(today.getDate() - 4)))} to Earlier`;
          break;

        case "6_months|4_months":
          range = `${formatDate(new Date(new Date(new Date().setMonth(today.getMonth() - 6)).setDate(today.getDate() - 3)))} to ${formatDate(
            new Date(new Date(new Date().setMonth(today.getMonth() - 4)).setDate(today.getDate() - 3))
          )}`;
          break;

        case "4_months|2_months":
          range = `${formatDate(new Date(new Date(new Date().setMonth(today.getMonth() - 4)).setDate(today.getDate() - 2)))} to ${formatDate(
            new Date(new Date(new Date().setMonth(today.getMonth() - 2)).setDate(today.getDate() - 3))
          )}`;

          break;

        case "2_months|16_days":
          let date_15 = formatDate(new Date(new Date().setDate(today.getDate() - 16)));
          range = `${formatDate(new Date(new Date(new Date().setMonth(today.getMonth() - 2)).setDate(today.getDate() - 2)))} to ${date_15}`;
          break;

        case "15_days|8_days":
          range = `${formatDate(new Date(new Date().setDate(today.getDate() - 15)))} to ${formatDate(
            new Date(new Date().setDate(today.getDate() - 9))
          )}`;
          break;

        case "7_days|1_day":
          range = `${formatDate(new Date(new Date().setDate(today.getDate() - 8)))} to ${formatDate(
            new Date(new Date().setDate(today.getDate() - 2))
          )}`;
          break;

        case "check_in_date":
          const yesterday = new Date(today);
          console.log(yesterday, 'this is yesterday');
          yesterday.setDate(yesterday.getDate() - 1);
          range = `${formatDate(yesterday)} (Yesterday)`;
          break;

        default:
          range = "N/A";
      }

      // switch (key) {
      //   case "before_6_months":
      //     range = `${formatDate(new Date(today.setMonth(today.getMonth() - 6)))} to Earlier`;
      //     break;
      //   case "6_months|4_months":
      //     range = `${formatDate(new Date(today.setMonth(today.getMonth() - 6)))} to ${formatDate(
      //       new Date(today.setMonth(today.getMonth() + 2))
      //     )}`;
      //     break;
      //   case "4_months|2_months":
      //     range = `${formatDate(new Date(today.setMonth(today.getMonth() - 4)))} to ${formatDate(
      //       new Date(today.setMonth(today.getMonth() + 2))
      //     )}`;
      //     break;
      //   case "2_months|15_days":
      //     range = `${formatDate(new Date(today.setMonth(today.getMonth() - 2)))} to ${formatDate(
      //       new Date(today.setDate(today.getDate() - 15))
      //     )}`;
      //     break;
      //   case "15_days|7_days":
      //     range = `${formatDate(new Date(today.setDate(today.getDate() - 15)))} to ${formatDate(
      //       new Date(today.setDate(today.getDate() + 7))
      //     )}`;
      //     break;
      //   case "7_days|1_day":
      //     range = `${formatDate(new Date(today.setDate(today.getDate() - 7)))} to ${formatDate(
      //       new Date(today.setDate(today.getDate() + 6))
      //     )}`;
      //     break;
      //   case "check_in_date":
      //     const yesterday = new Date(today);
      //     yesterday.setDate(yesterday.getDate() - 1);
      //     range = `${formatDate(yesterday)} (Yesterday)`;
      //     break;
      //   default:
      //     range = "N/A";
      // }


      categories.push(`${label}\n \n(${range})`);
    });
    setCategories(categories)
    setBookingData(bookingData)
    setAdrData(adrData)


    // resolve({ categories, bookingData, adrData });

    // })

  };

  const [compPostion, setCompPosition] = useState({})




  const occupancyValues = useMemo(() => [
    occupancyPast || null,
    occupancyLDOW || null,
    occpuancyLysplit || null
  ], [occupancyPast, occupancyLDOW, occpuancyLysplit]);

  const [isDataPresent, setIsDataPresent] = useState(true)

  const fetchData = async () => {
    setTable1Data([])
    setTable2Data([])
    setTable3Data([])
    setTable4Data([])
    setCompPosition({})
    setOccupancyPast("")
    setOccupancyLDOW("")
    setOccupancyLysplit("")
    setRoomRevenueData([])
    setRoomSoldData([])
    setRoomSoldData([])
    setRevenueData([])
    setFilteredRevenueData([])
    setFilteredRoomSold([])
    setCategories([])
    setBookingData([])
    setAdrData([])

    try {
      const response = await axios.get(`/get_insights/${tenantId}/${selectedProperty}/${selectedRoomTypes}/${checkInDate}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });

      let data = await response.data.insight_data
      if (!data || (typeof data === 'object' && Object.keys(data).length === 0)) {
        setIsDataPresent(false);
        setIsLoaded(true)
        setIsWeekDayView(false)
        setRoomTypes([])
        return;
      }
      setIsDataPresent(true)
      setIsWeekDayView(false)

      setTimeout(() => {
        populateData(data.past_30_days_summary, data.booking_adr)
        processChartData(data.booking_adr);
      }, 50);




      const occupancyRow = data.past_checkin_reports.rows.find(row => row[0] === "Occupancy");

      let occupancyData = {}
      if (occupancyRow) {
        occupancyData = {
          past_occupancy: isValidNumber(occupancyRow[1]) ? parseFloat(occupancyRow[1]) : null,
          last_dow_occupancy: isValidNumber(occupancyRow[2]) ? parseFloat(occupancyRow[2]) : null,
          lysplit_occupancy: isValidNumber(occupancyRow[3]) ? parseFloat(occupancyRow[3]) : null
        };
      }

      if (selectedProperty == "all_props") {
        setAllCompPosition(data.competitive_positioning)

        let target_id = properties[0]['propertyId']
        setCompProperty(target_id)
        const result = {
          headers: data.competitive_positioning.headers,
          rows: [],
          sold_out_comp: 0,
          total_comp: 0,
        };

        const targetData = data.competitive_positioning[target_id];
        if (targetData) {

          result.rows = targetData.comp_data || [];
          result.sold_out_comp = targetData.sold_out || 0;
          result.total_comp = targetData.total_comp || 0;
        }
        setTable2Data(result)
        setCompPosition({
          "sold_out_comp": result.sold_out_comp,
          "total_comp": result.total_comp
        })

      }
      else {
        setTable2Data(data.competitive_positioning);

        setCompPosition({
          "sold_out_comp": data.competitive_positioning.sold_out_comp,
          "total_comp": data.competitive_positioning.total_comp
        })
      }
      setRoomTypes(data.room_type_map)
      const modifiedRows = data.past_checkin_reports.rows.slice(0, -1);
      const updatedTableData = {
        ...data.past_checkin_reports,
        rows: modifiedRows,
      };
      setTable1Data(updatedTableData);
      setTable4Data(data.booking_source)
      setOccupancyPast(occupancyData.past_occupancy);
      setOccupancyLDOW(occupancyData.last_dow_occupancy);
      setOccupancyLysplit(occupancyData.lysplit_occupancy);

      setIsLoaded(true)
    } catch (error) {
      setIsLoaded(true)
      console.error('Error fetching table data:', error);
    }
  };





  useEffect(() => {
    if (selectedProperty) {
      fetchData()
    };
  }, [selectedProperty, selectedRoomTypes]);


  const formatPrice = (price) => {
    if (!price) return price;

    const numericValue = parseFloat(price);

    if (isNaN(numericValue)) return price;

    // Format with rupee symbol and commas
    return `₹${numericValue.toLocaleString('en-IN')}`;
  };



  const renderTable = (data, table) => {
    if (data.length == 0 || data == undefined) {
      return (
        <table className="table table-bordered mt-2" style={{ fontSize: "0.8em" }}>
          <thead style={{ verticalAlign: 'center' }}>
            <tr>
              {[...Array(4)].map((_, index) => (
                <th key={index}>
                  <Skeleton variant="text" width={80} height={20} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(4)].map((_, cellIndex) => (
                  <td key={cellIndex}>
                    <Skeleton variant="rectangular" width="100%" height={20} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <>
        <div style={table === 4 ? { maxHeight: "95%", overflowY: "auto", height: "90%" } : table == 1 ? { height: "90%" } : {}}>

          <table className="table table-bordered mt-2" style={{ fontSize: "0.8em", height: table === 2 ? "70%" : table == 1 ? "100%" : table == 4 ? "98%" : "auto" }}>
            {data?.headers && (
              <thead style={{ verticalAlign: 'middle' }}>
                <tr>
                  {data.headers.map((header, index) => (
                    <th
                      style={{
                        position: "sticky", // Sticky header
                        top: 0, // Stick to the top
                        backgroundColor: "#fff", // Prevent overlap with rows during scroll
                        zIndex: 2, // Ensure header stays above other elements
                        textAlign: "left", // Align text for a cleaner look
                        padding: "8px", // Add padding for better visuals
                        borderBottom: "2px solid #ddd", // Add a border for clarity
                      }}
                      key={index}>
                      <div className={`d-flex flex-wrap ${table === 2 ? "text-orange" : ""}`}>
                        <div>{header}</div>
                        {index !== 0 && date[index - 1] && table === 1 && (
                          <DateComponent value={date[index - 1]} fontSize={0.7} />
                        )}

                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody >

              {(data.rows.length == 0 || data.rows[0].length == 0) ? (
                <tr>
                  <td colSpan={data.headers.length} style={{ textAlign: "center", padding: "20px", fontWeight: "bold" }}>
                    No data found
                  </td>
                </tr>
              ) : (
                (table === 2 ? data.rows.slice(0, 5) : data.rows).map((row, rowIndex) => {
                  const isRevPARorADR = row[0] === "RevPAR" || row[0] === "ADR"; // Check if the row is RevPAR or ADR
                  const firstValue = isRevPARorADR ? parseFloat(row[1].replace(/[^0-9.]/g, "")) : null; // Extract numeric value from the first cell
                  const secondValue = isRevPARorADR ? parseFloat(row[2]?.replace(/[^0-9.]/g, "")) : null; // Extract numeric value from the second cell
                  const thirdValue = isRevPARorADR ? parseFloat(row[3]?.replace(/[^0-9.]/g, "")) : null; // Extract numeric value from the third cell

                  // Determine the color for the first value
                  let textColor = null;
                  if (isRevPARorADR) {
                    if ((secondValue && firstValue > secondValue)) {
                      textColor = "green";
                    } else if ((secondValue && firstValue < secondValue)) {
                      textColor = "red";
                    } else {
                      textColor = "black";
                    }
                  }

                  return (
                    <tr
                      key={rowIndex}
                      style={
                        table === 2 && row[3] === "self" ? { fontWeight: "bold" } : 
                        table === 2
                          ? {
                            height: `${Math.max(100 / Math.min(5, data.rows.length), 20)}%`,
                          }
                          : table === 3
                            ? { borderWidth: "1px !important" }
                            : table == 1 ? { height: `${Math.max(100 / Math.min(4, data.rows.length), 25)}%` } : {}
                      }
                    >
                      {row.map((cell, cellIndex) =>
                        table === 2 && (cellIndex == 3 || cellIndex === 2 ) ? null : (
                          <td
                            key={cellIndex}
                            style={{
                              ...(table === 1 && cellIndex === 0
                                ? { fontWeight: "bold" }
                                : {}),
                              ...(cellIndex === 1 && isRevPARorADR
                                ? { color: textColor }
                                : {}),
                              ...(cellIndex == 1 && table == 2
                                ? { display: "flex", alignItems: "center" }
                                : {})
                            }}
                            

                          >
                            {(cellIndex === 1 && isRevPARorADR) ||
                              (table === 2 && cellIndex === 1) ||
                              (cellIndex === 2 && isRevPARorADR)
                              ? formatPrice(cell)
                              : cell}
                            {(cellIndex == 1 && table == 2  && row[2])?<img src={soldOutImg} alt="" style={{ height: '3em', width: '3em' }} /> :null}  
                          </td>
                        )
                      )}

                      {/* {row.map((cell, cellIndex) =>
                          (table === 2 && cellIndex === 2) ? null : ( // Exclude the second index when table is 2
                            <td
                              key={cellIndex}
                              style={
                                table === 1 && cellIndex === 0
                                  ? { fontWeight: "bold" }
                                  : cellIndex === 1 && isRevPARorADR
                                    ? { color: textColor } // Apply color to the first value in RevPAR or ADR
                                    : null
                              }
                            >
                              {(cellIndex === 1 && isRevPARorADR) ||
                              (table === 2 && cellIndex === 1) ||
                              (cellIndex === 2 && isRevPARorADR)
                                ? formatPrice(cell)
                                : cell}
                            </td>
                          )
                        )} */}


                      {/* {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={
                            table === 1 && cellIndex === 0
                              ? { fontWeight: "bold" }
                              : cellIndex === 1 && isRevPARorADR
                                ? { color: textColor } // Apply color to the first value in RevPAR or ADR
                                : null
                          }
                        >
                          {(cellIndex == 1 && isRevPARorADR) || (table == 2 && cellIndex == 1) || (cellIndex == 2 && isRevPARorADR) ? formatPrice(cell) : cell}

                        </td>
                      ))} */}
                    </tr>
                  );
                })

              )}
            </tbody>

          </table>
        </div>
      </>
    );
  };


  return (
    <>
      <DashboardSessionTracker dashboardId={37}> </DashboardSessionTracker>
      <section className='mt-3 container-fluid'>
        <>
          <div
          //  style={{ visibility: isValidData ? "visible" : "hidden" }}
          >
            <div className='ml-5'>
              {/* <div className='row'> */}
              <div style={{ borderRadius: '6px' }} className='py-3 px-3 ms-2'>
                <h3 className='bold'>Hi, {currUser['first_name']}!</h3>
              </div>
              {/* </div> */}
              <div className="ms-4  d-flex">
                <div>
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Property</InputLabel>
                    {typeof properties !== "undefined" &&
                      properties.length &&
                      selectedProperty ? (
                      <Select
                        size="small"
                        style={{ backgroundColor: "#fff", width: 'fit-content', fontSize: '0.85em' }}
                        value={selectedProperty}
                        label="Property"
                        onChange={handlePropertyChange}
                      >
                        <MenuItem value="all_props">All properties</MenuItem>
                        {properties.map((item) => (
                          <MenuItem value={item.propertyId}>
                            {item.propertyName}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : null}
                  </FormControl>
                </div>
                <div className='ms-4' >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Room Types</InputLabel>
                    <Select
                      labelId="past-checkin-roomtype"
                      size="small"
                      style={{ backgroundColor: "#fff", width: '12em', fontSize: '0.85em' }}
                      value={selectedRoomTypes}
                      label="Room Type"
                      onChange={handleRoomTypesChangedReport}
                    >
                      <MenuItem value="all_rooms">All Room</MenuItem>
                      {roomTypes && roomTypes.length != 0 && roomTypes.map((item) => (
                        <MenuItem key={item[0]} value={item[0]}>
                          {item[1]
                            .toLowerCase()
                            .replace(/-/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* // ) : null} */}
                  </FormControl>
                </div>
              </div>
            </div>
            {isLoaded ? (<>

              {isDataPresent ?
                (<>
                  <div className='container-fluid d-flex justify-content-between mt-4 flex-wrap ' style={{ gap: '20px' }}>
                    <div className="home-card " style={{
                      flex: '1 1 41%',
                      minWidth: '280px',
                    }}>
                      <div className='d-flex justify-content-between'>
                        <div className='text-orange fw-semibold'>Past Check-in Day Report</div>
                      </div>
                      {renderTable(table1Data, 1)}
                    </div>
                    <div className="col-4 home-card" style={{
                      flex: '1 1 31%',
                      minWidth: '280px',
                    }}>
                      {selectedProperty === "all_props" ? (
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="d-flex flex-column ">
                            <div className='d-flex align-items-center'>
                              <div className="text-orange fw-semibold mb-1" style={{ fontSize: "1em" }}>
                                Competitive Positioning
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                <Tooltip title="This shows the average price tracked in the 2 weeks before check-in for this date." arrow>
                                  <IconButton>
                                    <InfoIcon sx={{ color: 'black' }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="d-flex  align-items-center flex-wrap" style={{ fontSize: "10px" }}>
                              <div className="fw-bold">Past Check-in Day</div>
                              {date && <DateComponent value={date[0]} week={2} fontSize={1} />}
                            </div>
                            <div
                              style={{
                                padding: 5,
                                backgroundColor: "#FFAA5C",
                                borderRadius: 10,
                                color: "white",
                                fontSize: "0.6em",
                                width: "12em",
                                fontWeight: 'bold'
                              }}
                              className='mt-1'
                            >
                              Comp Sold Out: {compPostion.sold_out_comp}/{compPostion.total_comp}
                            </div>
                          </div>
                          <div className="d-flex flex-column  align-self-end mt-2">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Property</InputLabel>
                              {
                                properties.length > 0 && (
                                  <Select
                                    size="small"
                                    style={{ backgroundColor: "#fff", width: "10em", fontSize: '0.8em' }}
                                    value={compProperty}
                                    onChange={handleCompetition}
                                    label="Property"
                                  >
                                    {properties.map((item) => (
                                      <MenuItem key={item.propertyId} value={item.propertyId}>
                                        {item.propertyName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                            </FormControl>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="d-flex flex-column">
                            <div className='d-flex j align-items-center'>
                              <div className="text-orange fw-semibold mb-1" style={{ fontSize: "1em" }}>
                                Competitive Positioning
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                <Tooltip title="This is additional information" arrow>
                                  <IconButton>
                                    <InfoIcon sx={{ color: 'black' }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="d-flex align-items-center" style={{ fontSize: "10px" }}>
                              <div className="fw-bold">Past Check-in Day</div>
                              {date && <DateComponent value={date[0]} week={2} fontSize={0.8} />}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div
                              style={{
                                padding: 5,
                                backgroundColor: "#FFAA5C",
                                borderRadius: 10,
                                color: "white",
                                fontSize: "0.8em",
                                width: "12em",
                                fontWeight: 'bold'
                              }}
                            >
                              Comp Sold Out: {compPostion.sold_out_comp}/{compPostion.total_comp}
                            </div>
                            {selectedProperty === "all_props" && (
                              <div className="mt-2 align-self-end">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Property</InputLabel>
                                  {Array.isArray(properties) &&
                                    properties.length > 0 && (
                                      <Select
                                        size="small"
                                        style={{ backgroundColor: "#fff", width: "10em", fontSize: '1em' }}
                                        value={compProperty}
                                        onChange={handleCompetition}
                                      >
                                        {properties.map((item) => (
                                          <MenuItem key={item.propertyId} value={item.propertyId}>
                                            {item.propertyName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                </FormControl>
                              </div>
                            )}
                          </div>
                        </div>
                      )}


                      {table2Data && (
                        <>
                          {renderTable(table2Data, 2)}
                        </>
                      )
                      }
                    </div>
                    <div className='col-3  home-card' style={{
                      flex: '1 1 21%',
                    }}>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex flex-column'>
                          <div className='text-orange fw-semibold'>Past 30 days</div>
                          {date && <DateRangeComponent value={date[0]} fontSize={0.6}></DateRangeComponent>}

                        </div>
                      </div>
                      <div
                        className="d-flex flex-column mt-2"
                        style={{
                          height: '90%',
                          width: '100%', // Ensure it spans the full width of the parent
                        }}
                      >
                        {table3Data && table3Data.length > 0 ? (
                          table3Data.map((element, index) => {
                            const lastIndex = index === table3Data.length - 1;
                            const baseStyle = {
                              padding: '10px',
                              fontSize: '0.8em',
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              flex: 1, // Each child takes equal height
                            };

                            const conditionalStyle = lastIndex ? {} : { borderBottom: '1px solid #F6F6F6' };

                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between"
                                style={{ ...baseStyle, ...conditionalStyle }}
                              >
                                <div style={{ flex: 1, textAlign: 'left' }} className='fw-semibold'>{element[0]}</div>
                                <div style={{ flex: 1, textAlign: 'right' }}>{element[1]}</div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {Array.from({ length: 6 }).map((_, index) => (
                              <div
                                key={index}
                                className="d-flex"
                                style={{
                                  padding: '10px',
                                  fontSize: '0.9em',
                                  borderBottom: index !== 5 ? '1px solid #F6F6F6' : 'none',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  flex: 1, // Each skeleton takes equal height
                                }}
                              >
                                <div style={{ flex: 1, textAlign: 'left' }}>
                                  <Skeleton variant="text" width="80%" height="100%" />
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>
                                  <Skeleton variant="text" width="60%" height="100%" />
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>



                    </div>
                  </div>
                  <div className='container-fluid d-flex justify-content-between mt-4 flex-wrap' style={{ gap: '20px' }} >
                    <div style={{
                      flex: '1 1 42%',
                      minWidth: '280px',
                    }} className='d-flex justify-content-between'>
                      {
                        date && occupancyValues && (
                          <>
                            {(() => {
                              const cardsData = [
                                { title: 'Occupancy-', subTitle: 'Past Check-in Day', date: date ? date[0] : "", value: occupancyValues[0] },
                                { title: 'Occupancy-', subTitle: 'Last DOW Check-in', date: date ? date[1] : "", value: occupancyValues[1] },
                                { title: 'Occupancy-', subTitle: 'LYSPIT Check-in', date: date ? date[2] : "", value: occupancyValues[2] },
                              ];

                              return (
                                <div className="d-flex flex-column w-100" style={{ height: '100%' }}>
                                  <div
                                    className="home-card w-100"
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <div className="d-flex text-orange fw-semibold">Occupancy</div>
                                    <div className="d-flex justify-content-between flex-column" style={{ height: '90%' }}>
                                      {cardsData.map((card, index) => (
                                        <div key={index} className="mb-2 mt-4">
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex fw-bold">{card.subTitle}</div>
                                            {card.date && <DateComponent value={card.date} fontSize={0.6} />}
                                          </div>
                                          <div style={{ marginTop: "16px" }}>

                                            <LinearProgress value={parseInt(card.value, 10)} />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              );
                            })()}
                          </>
                        )
                      }

                      {/* <OccupancyDashboard
            date={date}
            occupancyValues={occupancyValues}
            occupancyValues={[
              occupancyPast || 0,
              occupancyLDOW || 0,
              occpuancyLysplit || 0
            ]}
          /> */}
                    </div>

                    <div className='home-card' style={{
                      flex: '1 1 56%',
                      minWidth: '300px',
                    }}>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex justify-content-between align-items-center flex-wrap'>
                          <div className='text-orange fw-semibold mr-2'>Past 30 Check-in Days</div>
                          {date && <DateRangeComponent value={date[0]} fontSize={0.6} ></DateRangeComponent>}

                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='fw-semibold'>Day of Week</div>
                          <Switch {...label}
                            checked={isWeekdayView}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                color: "black",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "orange",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                backgroundColor: "orange",
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "black",
                              },
                            }}
                            onChange={handleDayOfWeekChange} />
                        </div>
                      </div>
                      {filteredRoomSold.length != 0 && filteredRevenueData.length != 0 ?
                        (
                          <>
                            <HighchartsReact containerProps={{ id: 'revenueRR' }} highcharts={Highcharts} options={options} />
                          </>
                        ) : null}

                    </div>
                  </div>
                  <div className='container-fluid d-flex mt-4 justify-content-between mb-5 flex-wrap' style={{ gap: '20px' }}>
                    <div className='home-card mb-4' style={{
                      flex: '1 1 41%',
                      minWidth: '270px',
                      maxHeight: '31em'
                    }}>
                      <div className='d-flex align-items-center flex-wrap'>
                        <div className='text-orange fw-semibold me-2'>Booking Source</div>
                        <div className='d-flex'>
                          <div className="d-flex   align-items-center flex-wrap" style={{ fontSize: "10px" }}>
                            <div className="fw-bold">Past Check-in Day</div>
                          </div>
                          {date && <DateComponent value={date[0]} week={2} fontSize={0.6} />}
                        </div>
                      </div>

                      {renderTable(table4Data, 4)}
                    </div>
                    <div className='home-card mb-4' style={{
                      flex: '1 1 55%',
                      minWidth: '270px',
                    }}>
                      <div>
                        <div className='d-flex  align-items-center'>
                          <div className='text-orange fw-semibold'>Booking Time Graph</div>
                          <div className="col-1 d-flex align-items-center">
                            <Tooltip title="Without cancellation" arrow>
                              <IconButton>
                                <InfoIcon sx={{ color: 'black' }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='mr-2 fw-bold' style={{ fontSize: '0.8em' }}>Past Check-in Day</div>
                          {/* {date && <DateRangeComponent value={date[0]} fontSize={0.6}></DateRangeComponent>
                        } */}
                          {date && <DateComponent value={date[0]} week={2} fontSize={0.6} />}

                        </div>
                      </div>
                      {categories && bookingData && adrData ? (<>
                        <div className='mt-2'>
                          <HighchartsReact highcharts={Highcharts} options={options2} />
                        </div>
                      </>) : null}
                    </div>

                  </div>
                </>) : (
                  <>
                    {/* <div className="no-data-container mt-5">
                    No Insights Data Found
                  </div> */}
                    <div
                      className="d-flex justify-content-center align-items-center mt-5"
                      style={{
                        padding: "20px",
                        backgroundColor: "#f8d7da",
                        color: "#721c24",
                        border: "1px solid #f5c6cb",
                        borderRadius: "5px",
                        fontSize: "1.2em",
                        fontWeight: "bold",
                      }}
                    >
                      No Insights Found

                    </div>
                  </>
                )}
            </>) : (<>
            </>)

            }


          </div>

        </>



      </section>
    </>
  )
}

export default Home