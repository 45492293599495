import React, { useEffect, useState } from 'react'
import Home from "./Home"
import axios from "axios";
import { fetchToken } from "../Auth";
import { useNavigate } from "react-router-dom";
import BaseLayout from '../components/common/Layout/BaseLayout'
import Dashboard from "./Dashboard"


const HomeLayout = () => {
  const navigate = useNavigate();
  const [tenantId, setTenantId] = useState(null)
  const getProperties = async () => {
    try {
      const response = await axios.get(`/fetch_client_properties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setTenantId(parseInt(response.data.client_id));
      }
    }
    catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    getProperties()
  }, [])



  return (
    <>
      <BaseLayout>
        {tenantId == 1 && (
          <Dashboard />
        ) }
        {(tenantId != 1 && tenantId != null) && (
          <Home />
        )}
      </BaseLayout>
    </>

  )
}

export default HomeLayout