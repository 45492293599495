import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../assets/styles/home.css';

function LinearProgressWithLabel({ value }) {
  const [animatedValue, setAnimatedValue] = useState(0); 
  const isBlinking = value >= 100; 
  const clampedValue = Math.min(value, 100); 

  const segments = 10;
  const colors = [
    "#cc0000", "#e53935", "#e53935", "#ff4d4d", "#ffad33", "#ffad33",
    "#47d147", "#2eb82e", "#2eb82e", "#55E496",
  ];

  const segmentIndex = Math.min(Math.floor(clampedValue / (100 / segments)), segments - 1);
  const barColor = isBlinking 
  ? colors[segments - 1] ?? "#f3f3f3" 
  : colors[segmentIndex] ?? "#f3f3f3";


  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimatedValue(clampedValue);
    }, 300);
    return () => clearTimeout(timeout); 
  }, [clampedValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          mr: 1,
          animation: isBlinking ? 'blink 1.5s infinite ease-in-out' : 'none', 
          transition: 'all 0.5s ease-in-out',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={animatedValue}
          sx={{
            transition: 'all 1s ease-in-out', 
            bgcolor: '#f3f3f3',
            '& .MuiLinearProgress-bar': {
              bgcolor: barColor, 
            //   animation: isBlinking ? 'blink 1s infinite' : 'none', 
            },
            height: '1.25em', 
            borderRadius: '10px', 
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {console.log(value)}
      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: "bold" }}>
        {value ? `${Math.round(value)}%` : value === 0 ? "0" : "-"}
      </Typography>

      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
